<!--
 * @Author: your name
 * @Date: 2021-08-03 14:37:41
 * @LastEditTime: 2021-09-25 16:53:59
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \xh-web\src\views\user\organization\components\Ostudent.vue
-->
<template>
  <div class="noJoin">
    <div class="student" v-if="affirm == 2">
      <h1>您当前的身份是：<span>学生志愿者</span></h1>
      <p>您还没有加入任何志愿服务队，请及时加入，积极参与志愿活动！</p>
      <div class="btn" @click="$router.push({ name: 'serve' })">立即加入</div>
    </div>
    <div class="member" v-if="affirm == 1">
      <h1>您当前的身份是：<span>注册志愿者</span></h1>

      <div class="heng flex-h flex-vc">
        <span>如您所在的志愿服务队已入驻平台，请及时加入！</span>
        <div class="btn" @click="$router.push({ name: 'serve' })">立即加入</div>
      </div>
    </div>
    <div class="member" v-if="affirm == 3">
      <h1>您当前的身份是：<span>会员志愿者</span></h1>
      <div class="heng flex-h flex-vc" v-if="objData.teamLeader==1 && objData.state==2">
        <span
          >您还没有创建任何志愿服务队，请及时创建，以便让更多志愿者可以加入！</span
        >
        <div class="btn" @click="$bus.$emit('cutOrganization', 'found')">
          立即创建
        </div>
      </div>
      <div class="heng flex-h flex-vc">
        <span>如您所在的志愿服务队已入驻平台，请及时加入！</span>
        <div class="btn" @click="$router.push({ name: 'serve' })">立即加入</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
    props: {
    objData: {
      type: Object,
      default: {
        serviceOrientation: [],
      },
    },
  },
  computed: {
    affirm() {
      return this.$store.state.affirm;
    },
  },
};
</script>
<style lang="scss" scoped>
.noJoin {
  padding-top: 38px;
  .btn {
    width: 145px;
    height: 37px;
    background: #ff6e6e;
    border-radius: 24px;
    cursor: pointer;
    text-align: center;
    font-size: 16px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #ffffff;
    line-height: 37px;
  }
  h1 {
    margin-left: 30px;
    font-size: 18px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #000000;
    span {
      color: rgba(255, 110, 110, 1);
    }
  }
  .student {
    p {
      margin-top: 184px;
      font-size: 16px;
      font-family: MicrosoftYaHei;
      color: #000000;
      text-align: center;
    }
    .btn {
      margin: 135px auto;
    }
  }
  .member {
    h1 {
      margin-bottom: 40px;
    }
    .heng {
      margin-left: 31px;
      margin-bottom: 22px;
      span {
        width: 480px;
        height: 19px;
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #000000;
      }
    }
  }
}
</style>