<!--
 * @Author: your name
 * @Date: 2021-08-03 15:09:24
 * @LastEditTime: 2021-10-13 17:00:04
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \xh-web\src\views\user\organization\components\found.vue
-->
<template>
  <div class="o_member">
    <div class="last" @click="last">上一步</div>
    <div class="title">志愿服务队认证</div>
    　<el-scrollbar>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="150px"
        class="demo-ruleForm"
        style="width: 650px; padding-bottom: 50px"
        size="small"
      >
        <el-form-item label="服务队名称" prop="queueName">
          <el-input
            :disabled="$route.query.id > 0"
            v-model="ruleForm.queueName"
            maxlength="100"
            show-word-limit
            clearable
            placeholder="请输入志愿服务队名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="服务队编号" prop="queueCode">
          <el-input
            :disabled="$route.query.id > 0"
            v-model="ruleForm.queueCode"
            maxlength="15"
            show-word-limit
            clearable
            placeholder="请输入SYW开头的服务队编号"
          ></el-input>
        </el-form-item>
        <el-form-item label="组织类型" prop="organizationId">
          <el-select
            :disabled="$route.query.id > 0"
            v-model="ruleForm.organizationId"
            placeholder="请选择组织类型"
          >
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="服务队图标/logo" prop="queueLogo">
          <el-upload
            class="avatar-uploader"
            :action="`${$baseURL}/file/publicUpload`"
            :headers="headers"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <img
              v-if="ruleForm.queueLogo"
              :src="ruleForm.queueLogo"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <div slot="tip" class="el-upload__tip">建议尺寸200*200</div>
          </el-upload>
        </el-form-item>

        <el-form-item label="发起单位名称" prop="companyName">
          <el-input
            v-model="ruleForm.companyName"
            maxlength="100"
            show-word-limit
            clearable
            placeholder="请输入发起单位全称"
          ></el-input>
        </el-form-item>
        <el-form-item label="法定代表" prop="representative">
          <el-input
            v-model="ruleForm.representative"
            maxlength="100"
            show-word-limit
            clearable
            placeholder="证件对应的法人姓名"
          ></el-input>
        </el-form-item>

        <el-form-item label="统一社会信用代码" prop="socialCode">
          <el-input
            v-model="ruleForm.socialCode"
            maxlength="100"
            show-word-limit
            clearable
            placeholder="请输入社会信用代码"
          ></el-input>
        </el-form-item>

        <el-form-item label="负责人/联系人" prop="principal">
          <el-input
            v-model="ruleForm.principal"
            maxlength="100"
            show-word-limit
            clearable
            placeholder="请输入负责人姓名"
          ></el-input>
        </el-form-item>

        <el-form-item label="联系电话" prop="contactNumber">
          <el-input
            v-model="ruleForm.contactNumber"
            maxlength="100"
            show-word-limit
            clearable
            placeholder="请输入联系电话"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系邮箱" prop="contactEmail">
          <el-input
            v-model="ruleForm.contactEmail"
            maxlength="100"
            show-word-limit
            clearable
            placeholder="请输入联系邮箱"
          ></el-input>
        </el-form-item>
        <el-form-item label="活动区域" prop="location">
          <el-cascader
            :options="mapOptions"
            v-model="ruleForm.location"
            :props="{ value: 'label' }"
            clearable
          ></el-cascader>
        </el-form-item>

        <el-form-item label="详细地址" prop="address">
          <el-input
            v-model="ruleForm.address"
            maxlength="100"
            show-word-limit
            clearable
            placeholder="请输入服务队所在地详细地址"
          ></el-input>
        </el-form-item>

        <el-form-item label="核心成员" class="is-required">
          <selectUser ref="selectUser" />
        </el-form-item>
        <el-form-item label="主要服务方向" prop="activityType">
          <el-checkbox-group v-model="ruleForm.activityType">
            <el-checkbox
              v-for="(item, index) in Typeoptions"
              :label="item.id"
              :key="index"
              >{{ item.typeName }}</el-checkbox
            >
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="志愿宣言" prop="manifesto">
          <el-input
            type="textarea"
            v-model="ruleForm.manifesto"
            maxlength="100"
            show-word-limit
            placeholder="请输入服务队志愿宣言/志愿理念（100字以内）"
          ></el-input>
        </el-form-item>
        <el-form-item label="组织简介" prop="organizationRemark">
          <el-input
            type="textarea"
            v-model="ruleForm.organizationRemark"
            maxlength="100"
            show-word-limit
            placeholder="请输入组织简介"
          ></el-input>
        </el-form-item>
        <div
          class="button"
          @click="updateForm('ruleForm')"
          v-if="$route.query.id"
        >
          提 交
        </div>
        <div class="button" @click="submitForm('ruleForm')" v-else>提 交</div>
      </el-form>
    </el-scrollbar>
  </div>
</template>
<script>
import { getToken } from "@/utils/auth";
import options from "@/utils/country-level3-data";
import {
  organizationList,
  activityTypeList,
  createQueue,
  queueDetailInfo,
  queueUpdate 
} from "@/api/index";
import selectUser from "@/components/selectUser.vue";
export default {
  components: {
    selectUser,
  },
  data() {
    return {
      type: 1,
      headers: {
        token: getToken(),
      },

      ruleForm: {
        queueName: "",
        queueCode: "",
        organizationId: "",

        queueLogo: "",

        companyName: "",
        representative: "",

        socialCode: "",
        location: [],
        principal: "",

        contactNumber: "",
        contactEmail: "",
        address: "",
        activityType: [],

        manifesto: "",
        organizationRemark: "",
      },
      options: [],
      Typeoptions: [],

      mapOptions: options,
      rules: {
        queueName: [
          { required: true, message: "请输入志愿服务队名称", trigger: "blur" },
        ],
        queueCode: [
          { required: true, message: "请输入志愿服务队编号", trigger: "blur" },
        ],
        organizationId: [
          { required: true, message: "请选择组织类型", trigger: "change" },
        ],
        queueLogo: [{ required: true, message: "请上传封面", trigger: "blur" }],
        companyName: [
          { required: true, message: "请输入发起单位名称", trigger: "blur" },
        ],
        representative: [
          { required: true, message: "请输入法定代表", trigger: "blur" },
        ],
        socialCode: [
          { required: true, message: "请输入社会信用代码", trigger: "blur" },
        ],
        principal: [
          { required: true, message: "请输入负责人姓名", trigger: "blur" },
        ],
        contactNumber: [
          { required: true, message: "请输入联系电话", trigger: "blur" },
        ],
        location: [
          { required: true, message: "请输入户籍地址", trigger: "change" },
        ],
        contactEmail: [
          { required: true, message: "请输入联系邮箱", trigger: "blur" },
        ],
        address: [
          { required: true, message: "请输入请输入详细地址", trigger: "blur" },
        ],
        activityType: [
          {
            type: "array",
            required: true,
            message: "请至少选择一个主要服务方向",
            trigger: "change",
          },
        ],

        manifesto: [{ required: true, message: "请输入内容", trigger: "blur" }],
        organizationRemark: [
          { required: true, message: "请输入内容", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    organizationList() {
      organizationList().then((res) => {
        this.options = res.data.list;
      });
    },
    activityTypeList() {
      activityTypeList().then((res) => {
        this.Typeoptions = res.data.list;
      });
    },
    last() {
      if (this.$route.query.id) {
        this.$router.go(-1);
      } else {
        this.$bus.$emit("cutOrganization", "noJoin");
      }
    },
    updateForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.ruleForm.addressDtoParam = {
            province: this.ruleForm.location[0],
            city: this.ruleForm.location[1],
            area: this.ruleForm.location[2],
            address: this.ruleForm.address,
          };
          let userList = this.$refs.selectUser.userList;
          this.ruleForm.coreMember = userList.map((item) => {
            return item.userId;
          });
          queueUpdate(this.ruleForm).then((res) => {
            this.$message({
              message: res.msg,
              type: "success",
            });
            this.last();
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.ruleForm.addressDtoParam = {
            province: this.ruleForm.location[0],
            city: this.ruleForm.location[1],
            area: this.ruleForm.location[2],
            address: this.ruleForm.address,
          };
          let userList = this.$refs.selectUser.userList;
          this.ruleForm.coreMember = userList.map((item) => {
            return item.userId;
          });
          console.log(this.ruleForm);
          createQueue(this.ruleForm).then((res) => {
            this.$confirm(
              "您的认证信息已提交成功，审核时间为7个工作日。审核通过后您将被赋予服务队的管理权限。",
              "提交成功",
              {
                confirmButtonText: "返回上一页",
                showCancelButton: false,
                type: "success",
                beforeClose: (action, instance, done) => {
                  done();
                  this.$bus.$emit("rest");
                },
              }
            );
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleAvatarSuccess(res, file) {
      this.ruleForm.queueLogo = res.data;
    },
    beforeAvatarUpload(file) {
      const isJPG =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg";
      const isLt2M = file.size / 1024 < 500;
      if (!isJPG) {
        this.$message.error("上传图片只能是 JPG/PNG 格式!");
        return false;
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 500K!");
        return false;
      }
      return true;
    },
    beforeUpload(file) {
      const isJPG =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg";
      const isLt2M = file.size / 1024 < 300;
      if (!isJPG) {
        this.$message.error("上传图片只能是 JPG/PNG 格式!");
        return false;
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 300K!");
        return false;
      }
      return true;
    },
  },
  created() {
    this.organizationList();
    this.activityTypeList();
    if (this.$route.query.id) {
      queueDetailInfo({
        id: this.$route.query.id,
      }).then((res) => {
        res.data.type = 1;
        let activityType = res.data.activityType.map((item) => {
          return parseInt(item);
        });
        res.data.activityType = activityType;
        res.data.location = [res.data.province, res.data.city, res.data.area];
        this.ruleForm = res.data;

        this.$nextTick(() => {
          let coreMember = res.data.coreMember.map((item) => {
            item.disabled = true;
            return item;
          });
          this.$refs.selectUser.userList = coreMember;
        });
      });
    }
  },
};
</script>
<style lang="scss">
.o_member {
  box-sizing: border-box;
  padding-top: 27px;
  position: relative;

  .last {
    cursor: pointer;
    font-size: 18px;
    font-family: MicrosoftYaHei;
    color: #ff6e6e;
    position: absolute;
    right: 35px;
  }
  .title {
    font-size: 20px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #000;
    text-align: center;
    margin-bottom: 25px;
  }

  .button {
    width: 140px;
    height: 40px;
    background: #ff6e6e;
    border-radius: 24px;
    cursor: pointer;
    text-align: center;
    font-size: 16px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #ffffff;
    line-height: 40px;
    margin-top: 80px;
    margin-left: 320px;
  }
  .el-scrollbar__wrap {
    height: 560px;
  }
}
</style>