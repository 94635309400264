<!--
 * @Author: your name
 * @Date: 2021-08-01 13:21:04
 * @LastEditTime: 2021-10-13 15:41:32
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \xh-web\src\views\user\userCenter\index.vue
-->
<template>
  <div class="organization">
    <div :is="component" :objData="Data"></div>
  </div>
</template>
<script>
import noJoin from "./components/noJoin.vue";
import join from "./components/join.vue";
import found from "./components/found.vue";
import { queueInfo } from "@/api/index";
export default {
  data() {
    return {
      component: "",
      Data: {
        serviceOrientation: [],
      },
    };
  },
  components: { noJoin, found, join },
  mounted() {
    this.$bus.$on("cutOrganization", (data) => {
      this.component = data;
    });
    this.$bus.$on("rest", () => {
      this.rest();
    });
  },
  methods: {
    rest() {
      queueInfo().then((res) => {
        if (res.data.param) {
          this.component = "join";
          this.Data = res.data;
        } else {
          this.Data = res.data;
          this.component = "noJoin";
        }
      });
    },
  },
  created() {
    if (this.$route.query.id) {
      this.component = "found";
    } else {
      this.rest();
    }
  },
};
</script>
<style lang="scss" scoped>
.organization {
  width: 822px;
  height: 642px;
  background: #fff6f6;
  border-radius: 23px;
  margin: 17px 0 0 15px;
}
</style>