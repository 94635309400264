<template>
  <div class="card flex-h flex-vc" @click="goDetail">
    <img :src="objData.queueLogo" />
    <div class="content">
      <h1>{{ objData.queueName }}</h1>
      <p>发起单位：{{ objData.companyName }}</p>
      <p class="flex-h" style="width: 450px;flex-wrap: wrap;">
        主要服务方向：<span
          v-if="objData.serviceOrientation && objData.serviceOrientation[0]"
          >{{ objData.serviceOrientation[0] }}</span
        >
        <span
          v-if="objData.serviceOrientation && objData.serviceOrientation[1]"
          >{{ objData.serviceOrientation[1] }}</span
        >
        <span
          v-if="objData.serviceOrientation && objData.serviceOrientation[2]"
          >{{ objData.serviceOrientation[2] }}</span
        >
      </p>
    </div>
    <!-- 创建者本身 -->
    <div v-if="objData.operationStatus == 0">
      <div class="type loading" v-if="objData.status == 0">审核中</div>
      <div class="type success" v-if="objData.status == 1">已通过</div>
      <div class="type error" v-if="objData.status == 2">未通过</div>
    </div>
    <!-- 其他成员 -->
    <div v-if="objData.operationStatus == 1 && objData.status == 1">
      <div class="type loading" v-if="objData.joinStatus == 0">审核中</div>
      <div class="type success" v-if="objData.joinStatus == 1">已通过</div>
      <div class="type error" v-if="objData.joinStatus == 2">未通过</div>
    </div>
    <div v-else>
      <div class="type loading" v-if="objData.status == 0">审核中</div>
      <div class="type error" v-if="objData.status == 2">未通过</div>
    </div>
    <div
      v-if="isMy"
      style="margin-left: 250px; margin-top: 10px"
      class="btn"
      @click.stop="
        $router.push({
          name: 'admin',
          query: { tab: privilege[0], id: objData.queueId },
        })
      "
    >
      进入管理中心
    </div>
  </div>
</template>
<script>
export default {
  props: {
    isMy: {
      type: Boolean,
      default: false,
    },
    objData: {
      type: Object,
      default: {
        serviceOrientation: [],
      },
    },
  },

  methods: {
    goDetail() {
      if (this.objData.status == 1) {
        this.$router.push({
          name: "serveDetail",
          query: {
            id: this.objData.queueId,
          },
        });
      }
    },
  },
  data() {
    return {
      type: "loading", //success error loading
    };
  },
  computed: {
    affirm() {
      return this.$store.state.affirm;
    },
     privilege() {
      return this.$store.state.privilege;
    },
  },
};
</script>
<style lang="scss" scoped>
.card {
  width: 650px;
  padding: 10px 0;
  background: #ffffff;
  box-shadow: 0px 2px 4px 0px #e9e5e5;
  border-radius: 10px;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  flex-wrap: wrap;
  img {
    width: 92px;
    height: 92px;
    border-radius: 50%;
    margin-left: 20px;
  }
  .content {
    margin-left: 19px;
    h1 {
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      margin-bottom: 10px;
    }
    p {
      margin-bottom: 6px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #737373;
      span {
        display: inline-block;

        height: 28px;
        background: #dbd7d7;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #797979;
        line-height: 28px;
        margin-right: 7px;
        padding: 0 16px;
        margin-bottom: 5px;
      }
    }
  }
  .type {
    width: 92px;
    height: 33px;

    border-radius: 0px 10px 0px 10px;
    position: absolute;
    right: 0;
    top: 0;
    text-align: center;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #ffffff;
    line-height: 33px;
    &.success {
      background: #2ea25b;
    }
    &.loading {
      background: #a8a6a6;
    }
    &.error {
      background: #fb3947;
    }
  }
  .btn {
    width: 145px;
    min-height: 37px;
    background: #ff6e6e;
    border-radius: 24px;
    cursor: pointer;
    text-align: center;
    font-size: 16px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #ffffff;
    line-height: 37px;
  }
}
</style>