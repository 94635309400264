<!--
 * @Author: your name
 * @Date: 2021-08-11 15:16:50
 * @LastEditTime: 2021-10-13 16:45:33
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \xh-web\src\components\selectUser.vue
-->
<template>
  <div class="select">
    <div v-for="(item, index) in userList" :key="index">
      <el-tag v-if="item.disabled">{{item.userName + '（' + item.phone + '）'}}</el-tag>
      <el-select
        v-else
        v-model="userList[index].userId"
        filterable
        remote
        reserve-keyword
        placeholder="请输入姓名或手机号"
        :remote-method="remoteMethod"
        :loading="loading"
        @change="handle(index)"
      >
        <el-option
          v-for="item in userOptions"
          :key="item.userId"
          :label="item.userName + '（' + item.phone + '）'"
          :value="item.userId"
        >
        </el-option>
      </el-select>
      <el-button
        type="text"
        style="margin-left: 10px"
        @click="userList.splice(index, 1)"
        >删除</el-button
      >
      <br />
      <span>证件号：{{ userList[index].idCard }}</span>
      <span>职业:{{ userList[index].position }}</span>
    </div>

    <el-button
      type="primary"
      icon="el-icon-plus"
      @click="userList.push({ userId: '', idCard: '', position: '' })"
      >添加核心成员
    </el-button>
    <i class="el-icon-question"
      >核心成员必须是平台认证的会员用户，且至少需求3位才能认证成功</i
    >
  </div>
</template>
<script>
import { userList } from "@/api/index";
export default {
  data() {
    return {
      userList: [
        {
          userId: "",
          idCard: "",
          position: "",
        },
      ],
      loading: false,
      userOptions: [],
    };
  },
  methods: {
    handle(index) {
      let userOptions = this.userOptions;
      userOptions.forEach((item) => {
        if (this.userList[index].userId == item.userId) {
          this.userList[index].idCard = item.idCard;
          this.userList[index].position = item.position;
        }
      });
    },
    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        userList({
          userName: query,
        }).then((res) => {
          this.loading = false;
          this.userOptions = res.data;
        });
        // setTimeout(() => {
        //   this.loading = false;
        //   this.options = this.list.filter((item) => {
        //     return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1;
        //   });
        // }, 200);
      } else {
        this.userOptions = [];
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.select {
  span {
    display: inline-block;
    color: #606266;
    &:nth-of-type(1) {
      width: 300px;
    }
  }
}
</style>