<!--
 * @Author: your name
 * @Date: 2021-08-03 17:09:58
 * @LastEditTime: 2021-09-25 14:10:24
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \xh-web\src\views\user\organization\components\join.vue
-->
<template>
  <div class="Join">
    <div class="box">
      <h3
        style="float: right"
        v-if="
          objData.param.status == 2 ||
          objData.param.joinStatus == 2 ||
          objData.param.joinStatus == 3 ||
          objData.param.joinStatus == 4 ||
          objData.param.queueStatus == 2
        "
      >
        <div
          class="btn"
          @click="$bus.$emit('cutOrganization', 'found')"
          v-if="objData.param.userType == 3"
        >
          创建服务队
        </div>
        <div class="btn" @click="$router.push({ name: 'serve' })">
          加入服务队
        </div>
      </h3>
      <h1>
        您当前的身份是：<span v-if="affirm == 2">学生志愿者</span
        ><span v-if="affirm == 1">注册志愿者</span>
        <span v-if="affirm == 3">会员志愿者</span>
      </h1>
      <h2>您加入的志愿服务队</h2>
      <organizationFWD
        :isMy="privilege.length > 0 && objData.param.status == 1"
        :objData="objData.param"
      />
      <div class="error" v-if="objData.param.joinStatus == 2">
        拒绝原因：<span>{{ objData.param.joinAuditRemark }} </span>
      </div>
      <div class="error" v-if="objData.param.status == 2">
        拒绝原因：<span>{{ objData.param.queueAuditRemark }} </span>
      </div>
    </div>
  </div>
</template>
<script>
import organizationFWD from "@/components/organization_fwd.vue";
import { privilegeInfo } from "@/api/index";
import { setPrivilege } from "@/utils/auth";
export default {
  props: {
    objData: {
      type: Object,
      default: {
        serviceOrientation: [],
      },
    },
  },
  components: {
    organizationFWD,
  },
  data() {
    return {
      type: "success", //success error loading
    };
  },
  computed: {
    affirm() {
      return this.$store.state.affirm;
    },
    privilege() {
      return this.$store.state.privilege;
    },
  },
  created() {
    privilegeInfo({
      id: "",
    }).then((res) => {
      setPrivilege(res.data.functionIds || []);
      this.$store.commit("SET_PRIVILEGE", res.data.functionIds || []);
    });
  },
};
</script>
<style lang="scss" scoped>
.Join {
  padding-top: 38px;
  .box {
    padding-left: 20px;

    h1 {
      font-size: 18px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #000000;
      span {
        color: rgba(255, 110, 110, 1);
      }
    }
    h3 {
      .btn {
        display: inline-block;
        width: 130px;
        height: 31px;
        background: #ff6e6e;
        border-radius: 24px;
        margin-right: 25px;
        cursor: pointer;
        text-align: center;
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #ffffff;
        line-height: 31px;
      }
    }
    h2 {
      font-size: 16px;
      font-family: MicrosoftYaHei;
      color: #545353;
      margin-top: 43px;
      margin-bottom: 22px;
    }
    .error {
      margin-top: 10px;
      font-size: 14px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: 900;
      color: #ff6e6e;
      span {
        font-weight: 500;
      }
    }
  }
}
</style>